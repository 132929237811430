<template>
  <section  class="uk-section uk-section-secondary uk-section-large">
    <div class="uk-container">
      <h2 class="uk-light uk-margin-top uk-text-center">Contacta a un agente de Cannonsoft</h2>

      <p> <span class="uk-text-bold"> Automaticemos lo aburrido. </span>Mejoremos tus procesos:</p>
        <form id="form">
            <fieldset class="uk-fieldset">
                <div uk-grid class="uk-child-width-1-2@s">
                    <div>
                        <input type="text" v-model="data.name" required class="uk-input uk-border-pill" placeholder="Tu nombre">
                    </div>
                    <div>
                        <input type="text" v-model="data.empresa" class="uk-input uk-border-pill" placeholder="Tu empresa (opcional)">
                    </div>

                </div>
                <br>
                <label>Descríbenos tu(s) proceso(s): <br> <br> </label>
                <textarea required v-model="data.desc" class="uk-textarea uk-border-rounded uk-form-width-large@m" placeholder="Cuéntanos tus problemas, en especial las partes tediosas y manuales"  rows="5"></textarea>
                <label> <br> <br> Cuéntanos tus ideas (opcional): <br> <br> </label>
                <textarea v-model="data.ideas" class="uk-textarea uk-border-rounded uk-form-width-large@m" placeholder="¿Te has imaginado tu página o tu app? ¿Cómo imaginas que sería? Queremos hacer tus ideas realidad. " rows="5"></textarea>

            </fieldset>
            <div uk-grid class="uk-child-width-1-2 uk-margin-top">
                <div>
                    <button type="submit" class="uk-button uk-button-primary uk-button-large uk-border-pill">Continuar</button>
                </div>
            </div>
        </form>
    </div>
  </section>  


    
<!-- modal -->
<div id="modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
        <button class="uk-modal-close-default" type="button" uk-close>Cerrar </button>
        <h2 class="uk-modal-title">Tu agente de <span class="uk-text-primary">Cannonsoft</span> </h2>
        <h4>Estás a punto de contactar a tu agente de <span class="uk-text-primary">Cannonsoft</span> </h4>
        <p>Se abrirá un chat de Whatsapp con un mensaje pre-escrito y listo para enviar a tu agente. <br> <br> 
            Una vez en Whatsapp solo da click en enviar y tu agente se pondrá en contacto contigo en breve.
        </p>
        <p>
            <button type="button" @click="wa()"  style="background-color:#25D366" class=" uk-button uk-button-secondary uk-button-large uk-border-pill">Ir a whatsapp &nbsp; <span uk-icon="whatsapp"></span></button>
        </p>
    </div>
</div>


</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
export default {
    setup(){
        const data = ref({})
        onMounted(()=>{
            window.scroll(0,0)
            document.getElementById('form').addEventListener('submit',(e)=>{
                e.preventDefault()
                UIkit.modal(document.getElementById('modal')).show()
            })
        })
        const wa = ()=>{

            var message = 'Hola, soy ' + data.value.name

            if(data.value.empresa){
                message += ', de la empresa ' + data.value.empresa
            }

            message += '\n\nMi proceso es el siguiente: \n' + data.value.desc

            if(data.value.ideas){
                message += '\n\nTengo y tengo estas ideas: \n' + data.value.ideas
            }

            window.open('https://wa.me/525567559889?text='+encodeURIComponent(message),'_blank')



        }
        return {
            data,
            wa
        }
    }

}
</script>

<style>

</style>